/*----------------------------------------------
Gallery
Gallery blocks, that can be images or
link blocks with content. Used for mondialite posts.

-----------------------------------------------*/
@mixin ratio-padding {
  padding-bottom: 15em;

  @media (min-width: $mq-med) {
    padding-bottom: 22em;
  }

  @media (min-width: $mq-xlarge) {
    padding-bottom: 20em;
  }

  @media (min-width: $mq-xxlarge) {
    padding-bottom: 22em;
  }
}

.gallery {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (min-width: $mq-med) {
    flex-direction: row;
  }

  &.animated {
    animation: bg-in 1s 1s ease both;
  }
}

.gal {
  position: relative;
  width: 50%;
  height: 15em;
  flex-basis: 100%;
  width: 100%;

  @include bg-img-cover();

  background-color: var(--darkgrey);
  padding-bottom: 45%;

  will-change: background;

  @media (min-width: $mq-med) {
    height: 10em;
    padding-bottom: 25%;
  }

  // firefox flexbox hack
  @-moz-document url-prefix() {
    @include ratio-padding;
  }

  @supports (-ms-ime-align:auto) {
    @include ratio-padding;
  }

  &.g-6 {
    @extend .g-6;
  }

  &.g-3 {
    @extend .g-3;
  }

  &.g-9 {
    @extend .g-9;
  }

  &__link {
    @include abs-fill;
    padding: 0 $grid-col-pad;
    color: #fff;
    overflow: hidden;
    @include link-styles(#fff, #fff)
  }

  &__bg {
    @include bg-img-abs();
    opacity: 0.92;
    transition: transform 5s, opacity 1s;
    @include engine3d('transform');
  }

  &__content {
    @include abs-flex-fill;

    @media (min-width: $mq-med) and (max-width: $mq-xlarge) {
      font-size: 85%;
    }

  }

  .sep-center {
    width: 0;
  }

  &__pretitle {
    display: inline-block;
    margin: 0 auto 1.5em;
    @extend %font-links;
  }

  &__title {
    @extend %font-title-light;
    text-align: center;
  }

  &__subtitle {
    opacity: 0;
    transform: translate3d(0,-3em,0);
    transition: all 0.4s ease;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    right: 2em;
  }

  .btn {
    margin-top: 1.5em;
  }
}

/*----------------------------------------------
-gal-link / gal-mondialite modifers
Link gallery blocks with titles
-----------------------------------------------*/
.gallery--links,
.gallery--events {

  // Gal Link Opacity
  .gal__bg {
    opacity: 0.6;
  }
}
// Gal Links Hover
.gallery--links .gal:hover,
.gallery--events .gal:hover {

  // Deal with Doubtap issue on ios
  @media (min-width: $mq-med) {
    .gal__bg {
      opacity: 0.3;
      transform: scale(1.2);
      transition: transform 9s ease, opacity 1s ease;
    }

    .gal__content, .gal__header {
      padding: $grid-col-pad;
      transition: 1.4s $ease-cb-1;
    }

    .sep-center {
      width: 4em;
      transition: width 1.2s ease;
    }

    .gal__subtitle {
      opacity: 1;
      transform: translate3d(0,0,0);
      transition: all 0.4s ease;
    }
  }
}

/*----------------------------------------------
Add margin if used inside grid-wrap
@see: scss/utils/_grid.scss
-----------------------------------------------*/
.gallery.grid-wrap {
  margin-bottom: $pad-sm;
}

@media (min-width: 54em) {
  .g-3, .gallery--events .gal:nth-of-type(1),
  .gallery--events .gal:nth-of-type(6),
  .gallery--events .gal:nth-of-type(9) {
    max-width: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
  }
}


@media (min-width: 54em) {
  .g-4, .gallery--events .gal:nth-of-type(1),
  .gallery--events .gal:nth-of-type(6),
  .gallery--events .gal:nth-of-type(9) {
    max-width: 33.333%;
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
  }
}

@media (min-width: 54em) {
  .g-8, .gallery--events .gal:nth-of-type(2),
  .gallery--events .gal:nth-of-type(5),
  .gallery--events .gal:nth-of-type(10) {
    max-width: 66.667%;
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
  }
}

@media (min-width: 54em) {
  .g-6,
  .gal.g-6,
  .gallery--events .gal:nth-of-type(3),
  .gallery--events .gal:nth-of-type(4),
  .gallery--events .gal:nth-of-type(7),
  .gallery--events .gal:nth-of-type(8) {
    max-width: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}
