//----------------------------------------------   
//  config
//  
//  01: font variables
//  02: media queries
//  03: colours
//  04: misc
//---------------------------------------------- 

//----------------------------------------------
// fonts
//----------------------------------------------
$font: "Apercu", sans-serif;
$font-mono: "Apercu Mono", monospace;

//----------------------------------------------
// fonts: lineheight
//---------------------------------------------- 
$base-lineheight: 1.5;

//----------------------------------------------
// fonts: size variables
//---------------------------------------------- 
$font-small: 0.9em;
$font-xsmall: 0.8em;
$font-xxsmall: 0.7em;
$font-xxxsmall: 0.6em;
$font-scale-up: 0.2em;
$font-mast-h1: 1.8em;
$font-h1: 1.9em;
$font-h2: 1.6em;
$font-h3: 1.4em;
$font-h4: 1.05em;
$font-h5: 1em;

// -----------------------------`-----------------
// Paddings
//-----------------------------------------------
$pad-xl:  8em;
$pad-lg:  7em;
$pad:     5em;
$pad-med: 3em;
$pad-sm:  2em;

// ----------------------------------------------
// Grid
//-----------------------------------------------
//$grid-max-width: 67em;
$grid-cols: 12;
// Grid col sizes
$grid-col: 100% / $grid-cols;
// Grid col pad
$grid-col-pad: 2%;
$grid-gutter: 2%;
$grid-max-width-sm: 42em;
$grid-max-width: 62em; 
$grid-max-width-lg: 85%;
$grid-max-width-xl: 95%;
$grid-width: 95%;

//----------------------------------------------
// media queries
//---------------------------------------------- 
// smallest phone
$mq-xsmall: 22em;
// phone
$mq-small: 32em;
// tablet
$mq-med: 54em;
// laptop
$mq-large: 65em;
// past laptop
$mq-xlarge: 91em;
// mid-size monitor
$mq-xxlarge: 115em;
// thunderbold goes to 157em
$mq-xxxlarge: 130em;

//----------------------------------------------
// z-indexes
//----------------------------------------------
$z-999: 999;
$z-998: 998;
$z-99: 99;
$z-9: 9;
$z-5: 5;
$z-2: 2;
$z-1: 1;
$z-0: 0;
$z-header: 92;
$z-menu: 91;
$z-lowest: -1;

//----------------------------------------------
// grid
//----------------------------------------------
$max-width: 45em;
$max-width--lg: $mq-large;
$max-width--xl: 81em;

//----------------------------------------------
// misc
//----------------------------------------------
$header-height: 4em;

$ease-cb-1: cubic-bezier(0.4, 0, 0.2, 1);
$ease-cb-2: cubic-bezier(0.19, 1, 0.22, 1);
$ease-cb-3: cubic-bezier(0.77, 0, 0.175, 1);

$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);

$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);

$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
$ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);
