//----------------------------------------------
// newsletter
//---------------------------------------------- 
.newsletter {

  &__container {
    @extend %auto-margin;

    max-width: $mq-small;
  }

  &__title {
    @extend %font-header;
    @extend %text-center;

    display: block;

    font-size: 1.25em;
    color: var(--white);
  }

  &__text {
    @extend %text-center;

    margin: {
      top: 0.75em;
      bottom: 1.75em;
    };

    color: var(--white);
  }

  &__error {
    @extend %font-header;
    @extend %text-center;

    margin: {
      top: 0.75em;
      bottom: 0;
    }

    color: var(--crimson);
  }
}
