//----------------------------------------------
// button
//----------------------------------------------
.btn {
  @include btn(#000);

  &.btn--white {
    @include btn(#fff);
  }
}

.btn-link {
  @include btn-link(#000);

  &.btn--white {
    @include btn-link(#fff);
  }
}

.btn--small {
  font-size: .9em;
  padding: .5em 1.5em;
}

.btn--full {
  display: block;
  width: 100%;
  text-align: center;
}

.butn {
  padding: 4em;
  color: var(--white);
  font-family: $font;
  background-color: var(--crimson);
}
