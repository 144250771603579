//----------------------------------------------
// pages
//----------------------------------------------
.pages {

  &__content {
    .circle,
    .circle > li {
      list-style-type: circle !important;
    }
  }

  &__intro {

    h2 {
      overflow: hidden;
      color: var(--crimson);

      span {
        display: block;
      }
    }

    p {
      font-size: 1.1em;

      @media (min-width: $mq-small) {
        font-size: 1.25em;
      }

      &:first-of-type {
        margin-top: 0.25em;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      .learn {
        text-align: center;
      }

    }

    a {
      @include link-underline(1px, var(--crimson));

      color: var(--black);
      white-space: nowrap;
      transition: color 400ms ease-in-out;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;

        height: 1px;
        width: 100%;

        background-color: var(--black);
      }

      &:hover {
        color: var(--crimson);
      }
    }
  }

  &__double {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 2em;
    grid-row-gap: 2em;

    @media (min-width: $mq-small) {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 0;
    }
  }
}
