//----------------------------------------------
// h1
//----------------------------------------------
%font-h1 {
  $font-size: 4em;

  font-size: $font-size - 2em;
  line-height: 1.3;

  @media (min-width: $mq-small) {
    font-size: $font-size - 0.5em;
  }

  @media (min-width: $mq-med) {
    font-size: $font-size;
  }
}

//----------------------------------------------
// h2
//----------------------------------------------
%font-h2 {
  $font-size: 2em;

  font-size: $font-size - 0.5em;

  @media (min-width: $mq-small) {
    font-size: $font-size - 0.25em;
  }

  @media (min-width: $mq-med) {
    font-size: $font-size;
  }
}

//----------------------------------------------
// h3
//----------------------------------------------
%font-h3 {
  font-size: 1.25em;
}

//----------------------------------------------
// font header
//----------------------------------------------
%font-header {
  @extend %letter-spacing;

  font-weight: 700;
  text-transform: uppercase;
}

//----------------------------------------------
// letter spacing
//----------------------------------------------
%letter-spacing {
  letter-spacing: 0.01em;
}

//----------------------------------------------
// text centre
//----------------------------------------------
%text-center {
  text-align: center;
}

/*----------------------------------------------   
--H5 Theme
-----------------------------------------------  */
%font-h5 {
  @extend %font-uppercase;
  margin-bottom: .4em;
  font-size: $font-h5;  
  line-height: 1.4;
  font-weight: 500;
}


%font-uppercase {
  text-transform: uppercase;
  letter-spacing: 0.15em;
}

%font-links {
  font-family: $font;
  font-size: $font-xsmall;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

%font-links-small {
  font-family: $font;
  font-size: $font-xxxsmall;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}



%font-title-xlight {
  font-family: $font;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  line-height: 1.4;
}

%font-title-light {
  font-family: $font;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.2em;
  line-height: 1.5;
}

%font-block-title {
  font-family: $font;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.125em;
}

%font-title {
  text-transform: uppercase;
  font-family: $font;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.2em;
}

%font-subtitle {
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.2em;
}

%font-subtitle-large {
  font-family: $font-mono;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.25em;
}

//----------------------------------------------
// seperators
//-----------------------------------------------
%sep,
.sep {
  position: relative;
  display: inline-block;
  vertical-align: baseline;
  height: 1px;
  width: 4em;
  margin: 1.6em 0;
  background-color: var(--darkgrey);
  border: 0;
}

%sep-center,
.sep-center {
  @extend %sep;
  margin: 1.85em auto;
  display: block;
}


//----------------------------------------------
// Seperator Colod Modifiers
//----------------------------------------------
.sep--white {
  background-color: var(--white);
}
