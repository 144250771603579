//----------------------------------------------
// intro
//----------------------------------------------
.intro {
  @extend %flex-align;

  height: 22em;
  margin-top: 3.5em;
  padding: 4em 1em;

  @media (min-width: $mq-small) {
    height: 38em;
    margin-top: 0;
    padding: 6em 2em;
  }

  @media (min-width: $mq-med) {
    padding: 8em 4em;
    min-height: 100vh;
  }

  h2 {
    color: var(--crimson);
  }

  &__text {
    @extend %auto-margin;
    @extend %text-center;

    @media (min-width: $mq-small) {
      max-width: 90%;
    }

    @media (min-width: $mq-large) {
      max-width: 65%;
    }
  }
}
