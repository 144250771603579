//----------------------------------------------
// footer
//----------------------------------------------
.footer {
  position: relative;
  padding: 3em 0 2em;
  color: var(--darkgrey);
  text-align: center;

  &__navs {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: $grid-max-width-xl;
    margin: 0 auto;

    @media (min-width: $mq-med) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__slogan {
    @extend %font-links;
    font-size: $font-xsmall;
    margin: 0 auto;

    @media (min-width: $mq-med) {
      z-index: 0;
      position: absolute;
      top: 10%;
      left: 0;
      right: 0;
    }

    @media (max-width: $mq-med) {
      order: 1;
      padding-bottom: 2em;
    }

    @media (min-width: $mq-med) and (max-width: $mq-large) {
      max-width: 15em;
    }
  }

  &__nav {
    z-index: 2;
    position: relative;

    @media (max-width: $mq-med) {
      order: 2;
      padding: 0.5em 0;
    }
  }

  &__nav ul {
    @extend %no-list-style;

    display: flex;
    align-items: center;

    a {
      @include nav-links(#191919);
    }
  }

  &__nav:first-child ul {
    li {
      padding: 0 1em;

      @media (min-width: $mq-xlarge) {
        padding: 0 1.75em;
      }

      &:first-child {
        @media (min-width: $mq-med) {
          padding-left: 0;
        }
      }
    }
  }

  &__nav:last-child ul {
    li {
      padding: 0 1em;

      @media (min-width: $mq-xlarge) {
        padding: 0 1.75em;
      }

      &:last-child {

        @media (min-width: $mq-med) {
          padding-right: 0;
        }
      }
    }
  }

  &__copy {
    margin: 0 auto;
    font-size: $font-xxsmall;
    @extend %font-uppercase;
  }
}
