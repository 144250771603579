//----------------------------------------------
// media
//----------------------------------------------
img,
svg,
figure,
picture,
object,
embed,
iframe,
video {
  max-width: 100%;
  height: 100%;
}

figure {
  margin: 0;
}

img {
  display: block;
}

iframe {
  border: none;
}

//----------------------------------------------
// absolute background
//----------------------------------------------
.absolute-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $z-0;

  height: 100%;
  width: 100%;

  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

//----------------------------------------------
// absolute video
//----------------------------------------------
.absolute-vid {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $z-0;

  height: 100%;
  width: 100%;

  overflow: hidden;

  video {
    @extend %auto-margin;

    position: absolute;
    top: 0;
    left: 0;
    z-index: $z-0;

    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;

    background-size: cover;
    object-fit: cover;
  }
}

//----------------------------------------------
// flex vid
//----------------------------------------------
.flex-vid {
  position: relative;

  overflow: hidden;

  &:before {
    content: "";

    display: block;
    padding-bottom: 56.5%; // Widescreen
  }

  iframe,
  object,
  embed,
  video {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;
  }
}

//----------------------------------------------
// image grid
//----------------------------------------------
.img-grid {
  &:after {
    content: '';
    display: table;
    clear: both;
  }

   &__item {
     max-width: 100%;
     margin-bottom: 0;

     @media (min-width: $mq-med) {
       float: left;
     }

     img {
      display: block;
     }
   }
   // if 1 child, 100%
   &__item:first-child:nth-last-child(1) {
      display: block;
      width: 100%;
   }

   // if 2 children, 50%
   &__item:first-child:nth-last-child(2),
   &__item:first-child:nth-last-child(2) ~ &__item {

     @media (min-width: $mq-med) {
       width: 50%;
     }
   }

   // if 3 children, 33.333%
   &__item:first-child:nth-last-child(3),
   &__item:first-child:nth-last-child(3) ~ &__item {

     @media (min-width: $mq-med) {
       width: 33.3333%;
     }
   }
 }
