//----------------------------------------------
// articles grid
//----------------------------------------------
.h-articles {
  padding: 0;

  &__grid {
    @include flex-grid(1,2);
  }
}

//----------------------------------------------
// posts
//----------------------------------------------
.posts {

  h2 {
    color: var(--crimson);
  }

  &__container {
    margin: {
      top: 3em;
      bottom: 4em;
    }
  }

  .previous {
    margin-right: 1em;
  }

  .post {
    transition: background-color 0.2s ease-in-out;

    &:nth-child(even) {
      background-color: var(--white);
    }

    &:nth-child(odd) {
      background-color: var(--lightgrey);
    }

    &:hover {
      background-color: var(--alpha-light);

      .post__more {
        box-shadow: inset 175px 0 0 0 var(--alpha);
        color: var(--white);
      }
    }

    &__link {
      display: block;
      padding: 2em 1em;

      @media (min-width: $mq-med) {
        padding: 4em 8em;
      }

      @media (min-width: $mq-xxlarge) {
        padding-left: 10em;
        padding-right: 10em;
      }
    }

    &__date {
      font-family: $font-mono;
      color: var(--black);
    }

    &__header {
      color: var(--alpha);

      margin-bottom: 0.5em;
    }

    &__text {
      color: var(--black);
    }

    &__more {
      @include button-slide();
    }
  }

  &__pagination {
    @extend %flex-center;

    margin-top: 1em;

    @media (min-width: $mq-small) {
      margin-top: 3em;
    }
  }

  &__more {
    font-weight: 500;
    @include button-slide();

    &:hover {
      box-shadow: inset 175px 0 0 0 var(--alpha);
      color: var(--white);
    }
  }
}

//----------------------------------------------
// article
//----------------------------------------------
.article {

  &__content {

    img {
      margin: {
        bottom: 1.5em;
        top: 1.5em;
      }
    }

    .flex-vid {
      margin: {
        bottom: 1.5em;
        top: 1.5em;
      }

      iframe {
        display: block;
      }
    }
  }

  &__text {
    h3 {
      overflow: hidden;
      display: block;
      margin-bottom: 0.75em;
    }

    p {
      font-size: 1.1em;

      @media (min-width: $mq-small) {
        font-size: 1.25em;
      }

      &:first-of-type {
        margin-top: 0.25em;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    a {
      @include link-underline(1px, var(--crimson));

      color: var(--black);
      white-space: nowrap;
      transition: color 400ms ease-in-out;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;

        height: 1px;
        width: 100%;

        background-color: var(--black);
      }

      &:hover {
        color: var(--crimson);
      }
    }
  }
}

//----------------------------------------------
// pagination
//----------------------------------------------
.pagination {
  background-color: var(--black);

  &__link {
    display: block;
    height: 25em;
    padding: 6em 0;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;

    &:after {
      @extend %overlay;
    }

    &:hover {
      color: var(--white);
    }

    &:hover:after {
      background-color: rgba(159, 143, 104, 0.8);
    }

    h2,
    span {
      color: var(--white);
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    text-align: center;

    span {
      @extend %letter-spacing;

      font-family: $font-mono;
    }
  }
}

//----------------------------------------------
// load more
//----------------------------------------------


//----------------------------------------------
// content
//----------------------------------------------
#markdown {
  @extend %flex-align;

  flex-direction: column;

  & > * {
    min-width: 100%;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5em;
  }

  h1 {
    font-size: 2.25em;
    line-height: 1.2;
  }

  h2 {
    font-size: 2em;
    line-height: 1.2;
  }

  h3 {
    font-size: 1.75em;
    line-height: 1.3;
  }

  h4 {
    font-size: 1.5em;
    line-height: 1.3;
  }

  h5 {
    font-size: 1.25em;
    line-height: 1.4;
  }

  h6 {
    font-size: 1.1em;
  }

  p {
    margin-bottom: 1.5em;

    font-size: 1.1em;

    @media (min-width: $mq-small) {
      font-size: 1.25em;
    }

    img {
      margin-bottom: 0;
    }
  }

  blockquote {
    p {
      margin-bottom: 0;
    }
  }

  a {
    @include link-underline(1px, var(--crimson));

    color: var(--black);
    white-space: nowrap;
    transition: color 400ms ease-in-out;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;

      height: 1px;
      width: 100%;

      background-color: var(--black);
    }

    &:hover {
      color: var(--crimson);
    }
  }

  hr {
    height: 1px;
    width: 100%;
    margin: {
      top: 0;
      bottom: 1.5em;
    }

    border: 0;
    background-color: rgba(var(--black-rgb), 0.1);
  }

  br {
    display: none;
  }

  img {
    display: block;
    margin-bottom: 1.5em;
  }

  img[src$="#full"] {
    width: 100vw;
    max-width: none;
  }

  .browser {
    margin-bottom: 1.5em;

    &__img {

      img {
        margin-bottom: 0;
      }
    }
  }

  .flex-vid {
    margin-top: 1.5em;
    margin-bottom: 1.5em;

    iframe {
      display: block;
    }
  }
}
