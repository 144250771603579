//----------------------------------------------
// cards
//----------------------------------------------
.card {
  overflow-y: hidden;

  &__link {
    position: relative;
    display:flex;
    justify-content:center;
    align-items:center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    text-align:center;
    padding: 7em 0;
    color: #fff;
    background-color: var(--darkgrey);
    overflow: hidden;
    transition: transform 0.4s $ease-cb-2, background 0.6s ease;
    @media (min-width: $mq-med) {
      padding: 7em 0;
    }
    @media (min-width: $mq-xlarge) {
      padding: 10em 0;
    }
    @media (min-width: $mq-xxxlarge) {
      padding: 12em 0;
    }

    &:hover, &:active, &:focus {
      cursor: pointer;
      color: #fff;
    }
  }

  // Background-Image holder
  &__bg{
    @include bg-img-abs();
    z-index: 0;
    padding-bottom: 25%;
    //animation: fade-in 5s ease;
    opacity: 0.7;
    transform: translateZ(0);
    transition: transform 5s, opacity 1s;
    @include engine3d('transform');

    .card__link:hover & {
      // prevent ios double tap
      @media (min-width: $mq-med) {
        opacity: 0.3;
        transform: scale(1.2);
        transition: transform 15s, opacity 1s;
      }
    }
  }

  // Grid
  [class*='grid'] {
    position: relative;
    align-self:center;
  }

  // Mast Content Wrapper
  &__content, &__header {
    position: relative;
    z-index: 4;
    // margin: 0 auto;
    padding: 0 $grid-col-pad;
    animation: fade-in 1s 0.2s ease both;
  }

  &__header {
    width: 75%;
    max-width: $grid-max-width-sm;
  }

  &__pretitle {
    font-family: $font;
    margin: 0 auto 1.5em;
    display: block;
    @extend %font-subtitle;
    font-size: $font-small;
  }

  // Title
  &__title {
    @extend %font-title-light;
    @extend %font-h2;
    margin: 0 auto;
  }
  // paragraph / text
  &__text {
    font-weight: 400;
    max-width: 30em;
    margin-left: auto;
    margin-right: auto;
  }

  // Card Subtitle
  &__subtitle {
    @extend %font-subtitle;
  }
  .sep-center {
    background-color: #fff;
  }
  .btn {
    margin-top: 2.5em;
  }

  &:hover {
    cursor: pointer;
  }
}
