//----------------------------------------------
// shop
//----------------------------------------------
.shops {

  h2 {
    color: var(--crimson);
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 2em;
    grid-row-gap: 4em;
    margin: {
      top: 1em;
      bottom: 1em;
    }

    @media (min-width: $mq-small) {
      grid-template-columns: repeat(2, 1fr);
      margin: {
        top: 3em;
        bottom: 4em;
      }
    }

    @media (min-width: $mq-med) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__shop {

    &:nth-child(3n+1) {

      .shops__text {
        transition-delay: 100ms;
      }
    }

    &:nth-child(3n+2) {

      .shops__link,
      .shops__scale,
      .shops__title span {
        transition-delay: 100ms;
      }

      .shops__text {
        transition-delay: 200ms;
      }
    }

    &:nth-child(3n+3) {

      .shops__link,
      .shops__scale,
      .shops__title span {
        transition-delay: 200ms;
      }

      .shops__text {
        transition-delay: 300ms;
      }
    }
  }

  &__link {
    display: block;

    color: var(--black);

    &:hover {

      .shops__img {
        transform: scale(0.97);

        figure {
          transform: scale(1.1);
        }
      }
    }
  }

  &__img {
    position: relative;

    background-color: var(--lightgrey);
    overflow: hidden;
    transition: transform 300ms ease-in-out;

    &:before {
      content: "";

      display: block;
      padding-top: 80%;
    }

    figure {
      transition: transform 300ms ease-in-out;
    }
  }

  &__scale {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;
  }

  &__header {
    margin-top: 1em;
  }

  &__title {
    overflow: hidden;

    span {
      display: inline-block;
    }
  }

  &__text {
    margin: {
      top: 0.25em;
      bottom: 0;
    }

    font-size: 1.1em;
  }
}

//----------------------------------------------
// cart
//----------------------------------------------
.show-sidebar-cart {
	overflow: hidden;
	height: 100%;

	#sidebar-cart {
		right: 0;
		visibility: visible;
	}
}

#sidebar-cart {
	background: var(--darkgrey);
	color: var(--lightgrey);
	padding: 15px 15px 0 15px;
	position: fixed;
	display: block;
	width: 320px;
	height: 100vh;
	z-index: 2;
	top: 0;
	right: -340px;
	box-shadow: -10px 0 15px rgba(0, 0, 0, 0.1);
	transition: right 0.5s ease-in-out;

	.close-button {
		height: 16px;
		width: 16px;
		margin: 0 0 15px 0;
		text-decoration: none;
		position: absolute;
		top: 20px;
		left: 20px;
		display: flex;

		.close-icon {
			width: 16px;
			height: 16px;
			background: rgba(var(--lightgrey), 0.3);
			text-indent: -9999px;
			overflow: hidden;
			font-size: 0;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23f4f4f4' d='M30.3 32c-.5 0-.9-.2-1.2-.5L16 18.4 3 31.5c-.4.3-.8.5-1.3.5s-.9-.2-1.2-.5c-.7-.7-.7-1.8 0-2.4L13.6 16 .5 2.9C-.2 2.2-.2 1.2.5.5.8.2 1.2 0 1.7 0s.9.2 1.2.5l13.1 13 13-13c.7-.7 1.7-.7 2.4 0 .4.3.6.7.6 1.2s-.2.9-.5 1.2L18.4 16l13.1 13.1c.7.7.7 1.8 0 2.4-.3.3-.8.5-1.2.5z'/%3E%3C/svg%3E");
			transition: all 0.5s linear;
		}
	}

	h2 {
		color: var(--lightgrey);
		font-size: 16px;
		font-weight: 600;
		text-transform: uppercase;
		margin: 5px 0 25px 0;
		display: flex;
		justify-content: center;

		.count {
			color: var(--lightgrey);
			background: var(--crimson);
			padding: 8px;
			margin-left: 6px;
			position: relative;
			top: 2px;
			width: 18px;
			height: 18px;
			border-radius: 50px;
			font-size: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.products {
		margin: 0;
		padding: 0 0 15px 0;
		list-style: none;
		height: calc(100vh - 200px);
		overflow-x: hidden;
		overflow-y: auto;
		display: block;
		position: relative;
		z-index: 0;

		&:after {
			background: linear-gradient(180deg, transparent, var(--darkgrey));
			height: 30px;
			width: 300px;
			z-index: 1;
			content: "";
			position: absolute;
			right: 0;
			bottom: 0;
		}

		.product {
			margin: 0 0 10px 0;
			padding: 0;
			width: 100%;
			min-height: 30px;
			background: var(--lightgrey);
			border-radius: 3px;
			color: var(--darkgrey);
			position: relative;
			z-index: 1;
			display: flex;
			flex-flow: row nowrap;
			transition: all 0.5s linear;

			.product-link {
				width: 100%;
				color: var(--lightgrey);
				padding: 10px;
				margin: 0;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;

				.product-image {
					display: inline-block;
					width: 75px;
					height: 50px;
					padding-right: 10px;

					img {
						width: 60px;
						height: 50px;
						border: 1px solid var(--grey);
						transition: all 0.5s linear;
					}
				}

				.product-details {
					display: inline-block;
					width: 100%;
					min-height: 30px;
					color: var(--darkgrey);

					h3 {
						margin: 3px 25px 5px 0;
						font-size: 13px;
						font-weight: 500;
						color: var(--darkgrey);
						transition: all 0.5s linear;
					}

					.qty-price {
						display: flex;
						flex-direction: row;
						flex-wrap: nowrap;
						align-content: center;
						align-items: center;
						justify-content: space-between;
						width: 100%;
						position: relative;
						z-index: 5px;
						margin-top: 8px;

						.qty,
						.price {
							display: flex;
							flex-direction: row;
							flex-wrap: nowrap;
						}

						.qty {
							display: flex;
							flex-direction: row;
							flex-wrap: nowrap;
							align-content: center;
							align-items: center;
							justify-content: flex-start;

							.minus-button,
							.plus-button {
								width: 25px;
								height: 24px;
								border-radius: 3px;
								border: 1px solid var(--grey);
								background: var(--lightgrey);
								color: var(--darkgrey);
								font-size: 18px;
								text-align: center;
								vertical-align: middle;
								line-height: 20px;
								transition: all 0.3s linear;

								&:focus {
									outline: none;
								}
							}

							.qty-input {
								width: 24px;
								height: 24px;
								text-align: center;
								border: 1px solid var(--grey);
								border-radius: 3px;
								margin: 0 2px;
								transition: all 0.5s linear;
                background: var(--white);

								&:focus {
									outline: none;
									border: 1px solid var(--lightgrey);
								}

								&::-webkit-inner-spin-button,
								&::-webkit-outer-spin-button {
									appearance: none;
									margin: 0;
								}
							}
						}

						.price {
							color: var(--darkgrey);
							font-weight: 500;
							font-size: 13px;
							display: inline-flex;
						}
					}
				}
			}

			.remove-button {
				height: 16px;
				width: 16px;
				margin: 10px 10px 0 0;
				text-decoration: none;
				position: absolute;
				top: 2px;
				right: 0;
				z-index: 2;
				display: flex;

				.remove-icon {
					width: 15px;
					height: 16px;
					background: rgba(var(--black), 0.5);
					text-indent: -9999px;
					overflow: hidden;
					font-size: 0;
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 36'%3E%3Cpath fill='%23bbb' d='M30.9 2.3h-8.6L21.6 1c-.3-.6-.9-1-1.5-1h-8.2c-.6 0-1.2.4-1.5.9l-.7 1.4H1.1C.5 2.3 0 2.8 0 3.4v2.2c0 .6.5 1.1 1.1 1.1h29.7c.6 0 1.1-.5 1.1-1.1V3.4c.1-.6-.4-1.1-1-1.1zM3.8 32.8A3.4 3.4 0 0 0 7.2 36h17.6c1.8 0 3.3-1.4 3.4-3.2L29.7 9H2.3l1.5 23.8z'/%3E%3C/svg%3E");
					transition: all 0.5s linear;
				}
			}
		}
	}

	.totals {
		position: absolute;
		bottom: 80px;
		left: 0;
		right: 0;
		height: 45px;
		background: var(--grey);
		border-bottom: 1px solid var(--grey);
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;

		.subtotal,
		.shipping,
		.tax {
			padding: 15px;
			text-align: center;
			color: var(--darkgrey);
			border-bottom: 1px solid var(--grey);
			text-transform: uppercase;
			font-size: 14px;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;

			.amount {
				color: darken(#861e2b, 15%);
				margin-left: 10px;
				font-weight: 600;
			}
		}
	}

	.action-buttons {
		padding: 0;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 80px;
		background: var(--lightgrey);
		display: block;
		white-space: nowrap;

		.view-cart-button,
		.checkout-button {
			display: inline-block;
			padding: 10px;
			margin: 20px 15px;
			text-align: center;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size: 14px;
			border-width: 1px;
			border-style: solid;
			border-radius: 4px;
			transition: all 0.5s linear;
		}

		.view-cart-button {
			background: var(--white);
			border-color: var(--crimson);
			margin-right: 5px;
			color: var(--crimson);
			width: 80px;
		}

		.checkout-button {
			border-color: var(--crimson);
			background: var(--crimson);
			margin-left: 5px;
			color: var(--white);
			width: 200px;

			&:after {
				content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23f4f4f4' d='M311.03 131.515l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887l-83.928 83.444c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l116.485-116c4.686-4.686 4.686-12.284 0-16.971L328 131.515c-4.686-4.687-12.284-4.687-16.97 0z'/%3E%3C/svg%3E");
				width: 20px;
				height: 14px;
				display: inline-block;
				margin-left: 6px;
				vertical-align: middle;
				position: relative;
				top: -5px;
				z-index: 1;
			}
		}
	}
}

#sidebar-cart-curtain {
	background: rgba(var(--black), 0.2);
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1;
}
