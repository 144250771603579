//--------------------------------------------
// sections
//--------------------------------------------
section {
  position: relative;
}

.body-border {
  border: 15px solid var(--white);
}

.section-padding {
  padding: 2em 1em;

  @media (min-width: $mq-small) {
    padding: 3em 2em;
  }

  @media (min-width: $mq-med) {
    padding: 4em;
  }
}

.section-padding--double {
  padding: 2em 1em;

  @media (min-width: $mq-small) {
    padding: 4em 2em;
  }

  @media (min-width: $mq-med) {
    padding: 6em 4em;
  }
}

//--------------------------------------------
// grid
//--------------------------------------------
.grid {
  @extend %auto-margin;

  width: 100%;
  max-width: $max-width--xl;

  @media (min-width: $mq-med) {
    width: 95%;
  }

  @media (min-width: $mq-xlarge) {
    width: 80%;
  }
}

.grid-small {
  @extend %auto-margin;

  width: 100%;
  max-width: $max-width;

  @media (min-width: $mq-med) {
    width: 65%;
  }

  @media (min-width: $mq-xlarge) {
    width: 50%;
  }
}

.grid-xsmall {
  @extend %auto-margin;

  width: 100%;
  max-width: 15em;

  @media (min-width: $mq-med) {
    width: 65%;
  }

  @media (min-width: $mq-xlarge) {
    width: 50%;
  }
}
