//----------------------------------------------  
//  flex-grid();
//  @description: Creates grid blocks via flexbox

//  @param: $sm, $med, $lg, $xl - @media sizes
//  @param: $pad  - item padding  
//  @useage: @include g-blocks(1, 2, 3, 4, 1%);

//  @note: Can replace universal selector with '& > #{$item}''  ($item:'article')
//----------------------------------------------
@mixin flex-grid($sm:null, $med:null, $lg:null, $xl:null, $pad:0) {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  margin-left: -$pad;
  margin-right: -$pad;

  & > * {
    padding:$pad;
    flex-basis: 100%/$sm;
    max-width: 100%/$sm;
    
    @media(min-width: $mq-med){
      flex-basis: 100%/$med;
      max-width: 100%/$med;
    }

    @if $lg {
      @media(min-width: $mq-large){
        flex-basis: 100%/$lg;
        max-width: 100%/$lg;
      }
    }

    @if $xl {
      @media(min-width: $mq-xlarge){
        flex-basis: 100%/$xl;
        max-width: 100%/$xl;
      }
    }
  }
}

//----------------------------------------------  
//  Flex Column
//  A helper to output cross browser requirements for 
//  columns inside a flexbox parent. flex-basis isn't
//  enough in all cases.
//
//  @param width 
//----------------------------------------------
@mixin flex-col($width, $mobile:false){
  @if $mobile == 'true' {
    flex-basis: $width;
    width: $width;
    max-width: $width;

  } @else {

  @media (min-width: $mq-med){
    flex-basis: $width;
    width: $width;
    max-width: $width;
  }
}
}

/*----------------------------------------------   
-Grid Cols: XSmall (mobile)
-----------------------------------------------*/
@for $i from 1 through $grid-cols {
 //Columns "xsm-*"  
 .g-xsm-#{$i} {
  max-width: ((100 / $grid-cols) * $i) * 1%;
  flex-basis: ((100 / $grid-cols) * $i) * 1%;
 }
}
/*----------------------------------------------
-Grid Cols: Med
-----------------------------------------------*/
@media (min-width: $mq-med) {
  @for $i from 1 through $grid-cols {
    //Columns "g-*"  
    .g-#{$i} {
      max-width: ((100 / $grid-cols) * $i) * 1%;
      flex-basis: ((100 / $grid-cols) * $i) * 1%;
    }
    // Offsets "os-*"
    .g-offset-#{$i}{
      margin-left: ((100 / $grid-cols) * $i) * 1%;
    }
  }
}
