//----------------------------------------------
// colours
//----------------------------------------------
:root {
  --white: #fff;
  --white-rgb: 255, 255, 255;
  --lightgrey: #f4f4f4;
  --lightgrey-rgb: 244, 244, 244;
  --grey: #bbb;
  --darkgrey: #191919;
  --darkgrey-rgb: 25, 25, 25;
  --black: #000;
  --black-rgb: 0, 0, 0;
  --crimson: #861e2b;
  --blue: #00415a;
  --darkblue: #001f2b;
  --alpha: #9f8f68;
  --alpha-light: #f5f3ef;
}

//----------------------------------------------
// base typography
//----------------------------------------------
body {
  font-family: $font;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  line-height: $base-lineheight;
  color: var(--black);
  background-color: var(--white);
}

//----------------------------------------------
// fluid body sizing
//----------------------------------------------
body {
  font-size: 100%;

  @media (min-width: $mq-small) {
    font-size: 102.5%;
  }

  @media (min-width: $mq-med) {
    font-size: 105%;
  }

  @media (min-width: $mq-large) {
    font-size: 107.5%;
  }

  @media (min-width: 75em) {
    font-size: 110%;
  }

  @media (min-width: $mq-xlarge) {
    font-size: 115%;
  }

  @media (min-width: $mq-xxlarge) {
    font-size: 120%;
  }

  @media (min-width: $mq-xxxlarge) {
    font-size: 125%;
  }
}

//----------------------------------------------
// headings
//----------------------------------------------
h1,
h2,
h3 {
  margin: {
    top: 0;
    bottom: 0;
  }

  font-family: $font;
  font-weight: 700;
}

//----------------------------------------------
// H1
//----------------------------------------------
h1 {
  $font-size: 4em;

  font-size: $font-size - 2em;
  line-height: 1.3;

  @media (min-width: $mq-small) {
    font-size: $font-size - 0.5em;
  }

  @media (min-width: $mq-med) {
    font-size: $font-size;
  }
}

//----------------------------------------------
// H2
//----------------------------------------------
h2 {
  $font-size: 2em;

  font-size: $font-size - 0.5em;

  @media (min-width: $mq-small) {
    font-size: $font-size - 0.25em;
  }

  @media (min-width: $mq-med) {
    font-size: $font-size;
  }
}

//----------------------------------------------
// H3
//----------------------------------------------
h3 {
  font-size: 1.25em;
  color: var(--crimson);
}

//----------------------------------------------
// paragraphs
//----------------------------------------------
p {
  margin: {
    top: 0;
    bottom: 0.75em;
  }
}

//----------------------------------------------
// blockquote
//----------------------------------------------
q,
blockquote {
  @extend %text-center;

  margin: 0 0 1.5rem;

  font-family: $font;
  font-weight: 400;
  font-size: 1.25em;
  color: var(--black);
  line-height: 1.4;

  @media (min-width: $mq-small) {
    font-size: 1.5em;
  }
}

cite {
  @extend %font-header;

  display: block;

  font-family: $font;
  font-size: $font-xxxsmall;
  font-style: normal;
  color: var(--black);
  line-height: $base-lineheight;

  &:before {
    content: "\2014 \0020";
  }
}

//----------------------------------------------
// misc
//----------------------------------------------
small {
  font-size: $font-xsmall;
}

strong {
  font-weight: 700;
}

sup,
sub {
  font-size: $font-xxsmall;
}

sup {
  top: -0.75em;
}

sub {
  bottom: -0.75em;
}

mark {
  color: var(--black);
}

//----------------------------------------------
// selection
//----------------------------------------------
::selection {
  color: var(--white);
  background-color: var(--darkgrey);
  text-shadow: none;
}
