//--------------------------------------------
// background colours
//--------------------------------------------
.bg-white {
  background-color: var(--white);
}

.bg-lightgrey {
  background-color: var(--lightgrey);
}

.bg-black {
  background-color: var(--black);
}

.bg-darkgrey {
  background-color: var(--darkgrey);
}

.bg-crimson {
  background-color: var(--crimson);
}

.bg-darkblue {
  background-color: var(--darkblue);
}

.bg-blue {
  background-color: var(--blue);
}

.bg-grey {
  background-color: var(--grey);
}

.bg-alpha {
  background-color: var(--alpha);
}
