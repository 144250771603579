//----------------------------------------------
// header
//----------------------------------------------
.header {
  @extend %flex-align;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $z-998;

  height: 4.5em;
  justify-content: space-between;

  background-color: var(--white);

  &__logo {
    width: 60px;
    height: 60px;

    @media (min-width: $mq-med) {
      width: 70px;
      height: 70px;
    }
  }

  //----------------------------------------------
  // cart bag
  //----------------------------------------------
  &__cart {
    z-index: $z-999;

    padding-right: 1em;

    @media (min-width: $mq-med) {
      padding-right: 2em;
    }
  }
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -9;

  height: 100%;
  width: 100%;

  visibility: hidden;
  opacity: 0;

  @media (min-width: $mq-med) {
    min-height: 40em;
  }

  .js-menu-visible & {
    z-index: 998;

    visibility: visible;
    opacity: 1;
  }


  &__container,
  &__img {
    position: absolute;
    top: 0;

    height: 100%;
  }

  &__container {
    left: 0;

    display: flex;
    width: 100%;

    overflow: hidden;
  }

  &__img {
    position: relative;

    display: none;

    @media (min-width: $mq-med) {
      display: block;
    }

    .absolute-bg {
      opacity: 0;
      transition: opacity 450ms cubic-bezier(0.25, 0.1, 0.25, 1) 300ms;

      .js-menu-open & {
        opacity: 1;
      }
    }
  }

  &__nav {
    display: flex;
    justify-content: center;

    margin: {
      top: 2em;
      bottom: 2em;
    }

    padding: {
      top: 3em;
      bottom: 3em;
    }

    &:first-of-type {
      flex-basis: 45%;
      max-width: 45%;

      border-right: 1px solid var(--black);
    }

    &:last-of-type {
      flex-basis: 55%;
      max-width: 55%;

      @media (min-width: $mq-large) {
        flex-basis: 45%;
        max-width: 45%;

        border-right: 1px solid var(--black);
      }
    }
  }

  &__list {
    @extend %no-list-style;

    display: flex;
    flex-direction: column;
  }

  &__item {
    overflow: hidden;

    &:first-child {
      margin-bottom: 2em;

      font-weight: 500;
      font-size: 0.9em;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }

    &:not(:first-child):not(:last-child) {
      margin-bottom: 0.25em;
    }

    @for $i from 2 through 6 {
      &:nth-child(#{$i}) {
        .menu__link {
          transition-delay: 100ms * $i;
        }
      }
    }

    &--img {
      width: 5em;
      margin-top: auto;

      opacity: 1;
      transition: opacity .6s cubic-bezier(.25,.1,.25,1) .2s;
    }

    &--contact {
      margin-top: auto;

      font-weight: 500;
      font-size: 0.8em;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }

    span {
      @include nth-transition-delay(3);

      opacity: 0;
      transition: opacity 600ms cubic-bezier(0.25, 0.1, 0.25, 1);

      .js-menu-open & {
        opacity: 1;
      }
    }
  }

  &__link {
    position: relative;

    display: inline-block;

    font-size: 1.2em;
    color: var(--black);
    opacity: 0;
    transform: translate3d(0, 100%, 0);
    transition: all 600ms cubic-bezier(0.25, 0.1, 0.25, 1);

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;

      height: 1px;
      width: 0;

      background-color: var(--black);
      transition: width 400ms ease;
    }

    &:hover {

      &:after {
        width: 100%;
      }
    }

    .js-menu-open & {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  &__sidebar {
    position: relative;

    display: none;

    @media (min-width: $mq-large) {
      display: block;
      flex-basis: 10%;
      max-width: 10%;
    }
  }

  &__flip {
    position: absolute;
    top: 0;
    left: 50%;

    transform: rotate(90deg) translateY(-50%);
    transform-origin: left top;
  }

  &__copy {
    display: inline-flex;
    justify-content: space-between;
    width: 100vh;
    padding: {
      right: 2em;
      left: 2em;
    }

    & > span {
      @include nth-transition-delay(3);

      font-size: 0.8em;
      opacity: 0;
      transition: opacity 600ms cubic-bezier(0.25, 0.1, 0.25, 1) 100ms;

      .js-menu-open & {
        opacity: 1;
        transition: opacity 600ms cubic-bezier(0.25, 0.1, 0.25, 1) 200ms;
      }
    }
  }

  &__external {
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: var(--black);
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
  }
}

//----------------------------------------------
// hamburger
//----------------------------------------------
.hamburger {
  z-index: $z-999;

  height: 15px;
  width: 26px;

  cursor: pointer;

  padding-left: 1em;

  @media (min-width: $mq-med) {
    padding-left: 2em;
  }

  & > div {
    height: 2px;
    width: 26px;
    margin-left: auto;

    background-color: var(--black);
    transition: all 200ms ease-in-out;

    &:nth-of-type(2) {
      width: 18px;
      margin: {
        top: 4px;
        bottom: 4px;
      }
    }
  }
}

.js-hamburger {

  & > div {
    background-color: var(--black);

    &:nth-of-type(1) {
      transform: translateY(6px) rotate(45deg);
    }

    &:nth-of-type(2) {
      opacity: 0;
    }

    &:nth-of-type(3) {
      transform: translateY(-6px) rotate(-45deg);
    }
  }
}

.js-menu-open {
  border-color: var(--darkgrey);
  transition-delay: 300ms;

  @media (min-width: $mq-med) {
    border-color: var(--lightgrey);
  }

  &:before {
    border-color: var(--darkgrey);
    transition-delay: 300ms;

    @media (min-width: $mq-med) {
      border-color: var(--lightgrey);
    }
  }
}

.js-menu-closing {
  cursor: wait;
}

.js-overflow {
  overflow: hidden;
}
