//----------------------------------------------
// flex centre
//----------------------------------------------
%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

//----------------------------------------------
// flex align
//----------------------------------------------
%flex-align {
  display: flex;
  align-items: center;
}

//----------------------------------------------
// auto margin
//----------------------------------------------
%auto-margin {
  margin-right: auto;
  margin-left: auto;
}

//----------------------------------------------
// list style
//----------------------------------------------
%no-list-style {
  list-style-type: none;
}

//----------------------------------------------
// cards
//----------------------------------------------
%cards {
  background-color: var(--white);
  overflow: hidden;
  transition: transform 300ms ease-in-out;
}

//----------------------------------------------
// shop card
//----------------------------------------------
%shop-card {
  background-color: var(--white);
  overflow: hidden;
  transition: transform 300ms ease-in-out;
}

//----------------------------------------------
// event card
//----------------------------------------------
%event-card {
  background-color: var(--white);
  overflow: hidden;
  transition: transform 300ms ease-in-out;
}

//----------------------------------------------
// pseudo square
//----------------------------------------------
%pseudo-square {

  &:before {
    content: "";

    display: block;
    padding-top: 80%;
  }
}

//----------------------------------------------
// overlay
//----------------------------------------------
%overlay {
  content: '';
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.4s ease-in-out;
}
