//----------------------------------------------
// product
//----------------------------------------------

.product {

  &__container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 6em;
    margin: {
      top: 4em;
      bottom: 3em;
    }

    @media (min-width: $mq-xsmall) {
      grid-template-columns: 1fr;
    }

    @media (min-width: $mq-med) {
      grid-template-columns: 2fr 1fr;
    }
  }

  &__img {
    width: 100%;
  }

  &__price {
    margin-bottom: 1em;
  }

  &__desc {
    margin-bottom: 0.5em;
  }

  &__button {
    margin-top: 1em;
  }
}


