.services {
  background-color: var(--black);

  // posts grid
  &__grid {
    @include flex-grid(1,2);
  }
}

.events {
  background-color: var(--lightgrey);

  h2 {
    @extend %flex-align;

    color: var(--white);
    padding-bottom: 2em;
  }

  // posts grid
  &__grid {
    @include flex-grid(1, 2, $pad: 1em);
  }

  li {
    margin: 10px 0;
  }

  a {
    display: flex;
    flex-wrap: no-wrap;
    align-items: center;

    text-decoration: none;
    color: var(--white);
    position: relative;
    background-color: var(--alpha);
    padding: 1em 1em 1em 0;
  }

  &__title {
    font-size: 1.2em;
    font-weight: 700;
  }

  &__date {
    min-width: 100px;
    text-align: center;
    color: var(--black);
    font-weight: 700;
    z-index: 0;
    margin-right: 10px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100px;
      height: 100%;
      background-color: var(--white);
      z-index: -1;
    }
  }
}
