//----------------------------------------------
// forms
//---------------------------------------------- 
form {
  display: flex;
  justify-content: center;
  width: 100%;
}

//----------------------------------------------
// inputs
//---------------------------------------------- 
input,
textarea {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0.75em 0.5em;

  font-family: $font;
  font-size: $font-small;
  font-weight: 400;
  color: var(--black);
  background: transparent;
  border: 2px solid rgba(0,0,0, 0.6);
  border-radius: 0;
  box-shadow: none;
  outline: 0;
  -webkit-appearance: none;
  transition: border-color 200ms ease-in-out;

  &:hover,
  &:focus {
    border-color: var(--black);
  }

  .newsletter & {
    color: var(--white);
    border: 2px solid rgba(255,255,255, 0.6);

    &:hover,
    &:focus {
      border-color: var(--white);
    }
  }
}

textarea {
  resize: none;
}

input[type="submit"] {
  position: relative;
  z-index: $z-1;

  font-weight: 700;
  cursor: pointer;
}

//----------------------------------------------
// labels
//---------------------------------------------- 
label {
  @extend %font-header;

  display: block;

  font-size: $font-small;
}

//----------------------------------------------
// group
//---------------------------------------------- 
.form-group {
  width: 50%;
  margin-right: 0.5em;
}

//----------------------------------------------
// placeholders
//---------------------------------------------- 
::placeholder {
  color: var(--grey);
}
