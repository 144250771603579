//----------------------------------------------
// icons
//----------------------------------------------
.gg-shopping-bag {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 3px;
  color: var(--black);

  &::after {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid;
    border-radius: 3px;
    content: "";
    position: absolute;
    width: 10px;
    height: 6px;
    border-bottom-left-radius: 120px;
    border-bottom-right-radius: 120px;
    border-top: 0;
    top: 2px;
    left: 3px;
  }
}

.gg-shopping-cart {
  display: block;
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 21px;
  background: linear-gradient(to left, var(--black) 12px, transparent 0) no-repeat -1px 6px / 18px 2px, linear-gradient(to left, var(--black) 12px, transparent 0) no-repeat 6px 14px / 11px 2px, linear-gradient(to left, var(--black) 12px, transparent 0) no-repeat 0 2px / 4px 2px, radial-gradient(circle, var(--black) 60%, transparent 40%) no-repeat 12px 17px / 4px 4px, radial-gradient(circle, var(--black) 60%, transparent 40%) no-repeat 6px 17px / 4px 4px;

  &::after, &::before {
    content: "";
    display: block;
    position: absolute;
    box-sizing: border-box;
    width: 2px;
    height: 14px;
    background: var(--black);
    top: 2px;
    left: 4px;
    transform: skew(12deg);
  }

  &::after {
    height: 10px;
    top: 6px;
    left: 16px;
    transform: skew(-12deg);
  }
}

.gg-facebook {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;

  &::after {
    content: "";
    display: block;
    position: absolute;
    box-sizing: border-box;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    box-sizing: border-box;
    width: 8px;
    height: 20px;
    border-left: 4px solid;
    border-top: 4px solid;
    border-top-left-radius: 5px;
    left: 6px;
  }

  &::after {
    width: 10px;
    height: 4px;
    background: var(--darkgrey);
    top: 7px;
    left: 4px;
    transform: skew(-5deg);
  }
}

.gg-instagram {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px;
  border-radius: 4px;
  width: 20px;
  height: 20px;

  &::after, &::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
  }

  &::after {
    border: 2px solid;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    top: 3px;
  }

  &::before {
    border-radius: 3px;
    width: 2px;
    height: 2px;
    background: var(--darkgrey);
    right: 1px;
    top: 1px;
  }
}

.gg-youtube {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 10px;
  box-shadow: 0 0 0 2px;
  border-radius: 15px 15px 15px 15px / 45px 45px 45px 45px;

  &::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    left: 7px;
    top: 2px;
    border-left: 4px solid var(--darkgrey);
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
  }
}
