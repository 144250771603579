//----------------------------------------------
// blocks
//----------------------------------------------
.blocks {
  display: block;
  background-color: var(--darkblue);
  color: #fff;

  @media (min-width: $mq-med) {
    display: flex;
    flex-direction: row;
  }

  a {
    @include link-underline(1px, var(--white));

    color: var(--white);
    white-space: nowrap;
    transition: color 400ms ease-in-out;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;

      height: 1px;
      width: 100%;

      background-color: var(--white);
    }

    &:hover {
      color: var(--white);
    }
  }
 }

.product-price {
  color: var(--white);
}

.block {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 30em;
  width: 100%;

  @media (min-width: $mq-med) and (max-width: $mq-xlarge) {
      flex-basis: 50%;
  }
  // Bg images blocks
  .bg-img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    min-height: 30em;
    width: 100%;
  }

  // Block Modifier: Vertically stacked blocks
  &--stacked {
    flex-direction: column;
    background-color: rgba(0,0,0,0);

    .bg-img {
      flex: 1;
      height: 50%;
      min-height: 15em;
      padding-bottom: 50%;

      @media (min-width: $mq-med) {
        padding-bottom: 29%;
      }
      // Firefox Flexbox Hack, since it doesnt accept percentages
      @-moz-document url-prefix() {
        padding-bottom: 15em;
        @media (min-width: $mq-med) {
          padding-bottom: 22em;
        }

        @media (min-width: $mq-xlarge) {
          padding-bottom: 20em;
        }

        @media (min-width: $mq-xxlarge) {
          padding-bottom: 22em;
        }
      }

      &:last-child {
        top: 50%;
      }
    }
  }

  // Block Content (blocks with text)
  &__content {
    padding: 5em 2.5em;
    margin: 0 auto;
    max-width:42em;

    @media (min-width: $mq-med) {
      padding: 5em 5.5em;
    }
  }
  // Block Title
  &__title {
    @extend %font-title-light;

    color: var(--white);
  }

  .preheader {
    font-size: $font-xsmall;
    display: block;
    margin: 0 auto 0.5em;
    font-weight: 600;
  }

  // Blockquote in block
  blockquote {
    cite {
      margin-top: 3em;
      font-style: normal;
      @extend %font-subtitle;
    }
  }

  img {
    display: block;
  }
}

.blocks--reverse {

  @media (min-width: $mq-med) {
    flex-direction: row-reverse;
  }
}

.blocks--imgs {
  .block {
    min-height: auto;
  }

  .block img {
    width: 100%;
  }
}
