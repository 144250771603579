//----------------------------------------------
// cta
//---------------------------------------------- 
.cta {

  &__link {
    @extend %flex-center;
    padding: 4em;
    color: var(--white);
    font-family: $font;

    &:hover {

      .cta__text {

        &:before {
          left: 0;

          width: 100%;
        }
      }
    }
  }

  &__text {
    @include block-underline(4px);
    @extend %font-h2;
  }
}
