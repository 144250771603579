//----------------------------------------------
// links
//----------------------------------------------
a {
  text-decoration: none;
}

//---------------------------------------------- 
//  Link Underline - link-underline()
//  @description: Underline for link elements
//  @param:       $height: [null] - Height
//  @param:       $color: [null] - Color
//----------------------------------------------
@mixin link-underline($height, $color) {
  position: relative;

  color: $color;
  transition: all 400ms ease;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: $z-9;

    height: $height;
    width: 0;
    
    background-color: $color;
    transition: width 400ms ease;
  }

  &:hover {

    &:before {
      left: 0;

      width: 100%;
    }
  }
}

//---------------------------------------------- 
//  Block Underline - block-underline()
//  @description: Underline for block elements
//  @param:       $height: [null] - Height
//----------------------------------------------
@mixin block-underline($height) {
  position: relative;

  font-weight: 700;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: $z-9;

    height: $height;
    width: 0;
    
    background-color: var(--white);
    transition: width 400ms ease;
  }
}
