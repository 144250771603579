//----------------------------------------------
// events
//----------------------------------------------
.events {

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 2em;
    margin-top: 1em;

    @media (min-width: $mq-small) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__events {
    padding: {
      top: 1em;
      bottom: 1em;
    }

    @media (min-width: $mq-small) {
      padding: {
        top: 2em;
        bottom: 2em;
      }
    }
  }

  &__link {
    display: block;

    color: var(--black);

    &:hover {
      .events__vid,
      .events__img {
        transform: scale(0.97);
      }

      .events__vid {

        video {
          transform: scale(1.1);
        }
      }

      .events__img {

        img {
          transform: scale(1.1);
        }
      }
    }
  }

  &__scale {

    .events__vid & {
      position: absolute;
      top: 0;
      left: 0;

      height: 100%;
      width: 100%;
    }
  }

  &__vid {
    @extend %event-card;
    @extend %pseudo-square;

    position: relative;

    video {
      @extend %auto-margin;

      position: absolute;
      top: 0;
      left: 0;
      z-index: $z-0;

      min-height: 100%;
      min-width: 100%;
      height: auto;
      width: auto;

      background-size: cover;
      object-fit: cover;
      transition: transform 300ms ease-in-out;
    }
  }

  &__img {
    @extend %event-card;

    img {
      transition: transform 300ms ease-in-out;
    }
  }

  &__header {
    margin-top: 1em;
  }

  &__title {
    overflow: hidden;

    span {
      display: inline-block;
    }
  }

  &__text {
    margin: {
      top: 0.25em;
      bottom: 0;
    }

    font-size: 1.1em;
    max-width: 26em;
  }
}

.eventer {
  position: relative;

  padding-top: 2em;

  background-color: var(--white);
  border: 1px solid rgba(var(--white-rgb), 0.9);
  border-top: {
    right-radius: 0.4em;
    left-radius: 0.4em;
  }
  box-shadow: 0px 1px 0px 1px rgba(var(--black-rgb), 0.02), 0px 8px 53px 7px rgba(var(--black-rgb), 0.09);

  video {
    display: block;
    width: 100%;
  }

  &__img {
    background-color: var(--lightgrey);
    border-top: 1px solid var(--lightgrey);

    img {
      width: 100%;
    }
  }
}
