//---------------------------------------------- 
//  transition delay - nth-transition-delay()
//  @description: transition delay to child elements
//  @param:       $items: [12] - Items
//  @param:       $time: [0.2s] - Time 
//----------------------------------------------
@mixin nth-transition-delay($items: 12, $time: 0.2s) {
  @for $i from 1 through $items {
    &:nth-child(#{$i}) {
      transition-delay: $time * $i;
    }
  }
}

//---------------------------------------------- 
//  animation delay - nth-animation-delay()
//  @description: animation delay to child elements
//  @param:       $items: [12] - Items
//  @param:       $time: [0.2s] - Time 
//----------------------------------------------
@mixin nth-animation-delay($items: 12, $time: 0.2s) {
  @for $i from 1 through $items {
    &:nth-child(#{$i}) {
      animation-delay: $time * $i;
    }
  }
}

//----------------------------------------------
//  nth-fade-ins()
//  @description: Sequential Fadein Animation via nth-of-type
//  @param:  $aniItems: [4]    - number of nth-of-type items to create
//  @param:  $aniDuration:[0.6s]   - animation duration
//  @param:  $aniStart:[0.6s]   -   animation start
//----------------------------------------------
@mixin nth-fade-ins($ani_items: 4, $ani_duration: 0.6s, $ani_start: 0.6s){
@for $i from 1 through $ani_items {
  &:nth-child(#{$i}) {   
   animation: fade-in $ani_duration $ani_start * $i both; 
  }
 }
}

/*----------------------------------------------
-Seperator Width animation
-----------------------------------------------*/
@mixin sep-width($speed: 1s, $start: 0s, $width: '100%'){
  animation: sep-width $speed $start $ease-cb  both;
  max-width: $width;
  @include engineGPU('width');

  @keyframes sep-width{
    0%   { width: 0;}
    100% { width: 100%;}
  }
}

//----------------------------------------------
//  engine3d()
//  @decrption: Activate browser's 3d engine for smoother FPS
//              But, use will-change first
//----------------------------------------------
@mixin engine3d($type: '') {
  will-change: $type;
  transform: translateZ(0);
  visibility: visible;
  -webkit-backface-visibility: hidden;
}
//----------------------------------------------
//  engineGPU()
//  @decrption: Trigger GPU, sans transform
//----------------------------------------------
@mixin engineGPU($type: ''){
  will-change: $type;
  visibility: visible;
  -webkit-backface-visibility: hidden;
}
