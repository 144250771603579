//----------------------------------------------
// courses, trainings, classes, healing pages
//----------------------------------------------
.programs {

  h2 {
    color: var(--crimson);
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 2em;
    grid-row-gap: 4em;
    margin: {
      top: 1em;
      bottom: 1em;
    }

    @media (min-width: $mq-small) {
      grid-template-columns: repeat(2, 1fr);
      margin: {
        top: 3em;
        bottom: 4em;
      }
    }

    @media (min-width: $mq-med) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__program {

    &:nth-child(3n+1) {

      .programs__text {
        transition-delay: 100ms;
      }
    }

    &:nth-child(3n+2) {

      .programs__link,
      .programs__scale,
      .programs__title span {
        transition-delay: 100ms;
      }

      .programs__text {
        transition-delay: 200ms;
      }
    }

    &:nth-child(3n+3) {

      .programs__link,
      .programs__scale,
      .programs__title span {
        transition-delay: 200ms;
      }

      .programs__text {
        transition-delay: 300ms;
      }
    }
  }

  &__link {
    display: block;

    color: var(--black);

    &:hover {

      .programs__img {
        transform: scale(0.97);

        figure {
          transform: scale(1.1);
        }
      }
    }
  }

  &__img {
    position: relative;

    background-color: var(--lightgrey);
    overflow: hidden;
    transition: transform 300ms ease-in-out;

    &:before {
      content: "";

      display: block;
      padding-top: 80%;
    }

    figure {
      transition: transform 300ms ease-in-out;
    }
  }

  &__scale {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;
  }

  &__header {
    margin-top: 1em;
  }

  &__title {
    overflow: hidden;

    span {
      display: inline-block;
    }
  }

  &__text {
    margin: {
      top: 0.25em;
      bottom: 0;
    }

    font-size: 1.1em;
  }
}

//----------------------------------------------
// reiki prices
//----------------------------------------------
.prices {

  &__rl {
    @extend %no-list-style;
  }
}
